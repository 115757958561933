import React, { useState } from 'react'
import CountUp from 'react-countup';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import img8 from '../Image/img-8.jpg'
import img5 from '../Image/img-5.jpg'
import img4 from '../Image/img-4.jpg'
import img6 from '../Image/img-6.jpg'
import img7 from '../Image/img7.jpg'
import img12 from '../Image/img12.jpg'
import img14 from '../Image/img-14.jpg'

const AboutContent = () => {
    const [CounterOn, setCounterOn] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    const AboutReadMore = () => {
        navigate('/about')
    }
    return (
        <div><div className="container-fuild pb-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6 text-end about-item overflow-hidden mt-5 pt-4" data-wow-delay="0.7s">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={img14}   />
                            </div>
                            <div className="col-6 text-start about-item overflow-hidden mt-5 pt-3" data-wow-delay="0.7s">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={img4} style={{ marginTop: '40%' }} />
                            </div>
                            <div className="col-6 text-end about-item overflow-hidden" data-wow-delay="0.7s">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={img8}/>
                            </div>
                            <div className="col-6 text-start about-item overflow-hidden" data-wow-delay="0.7s">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={img7}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-4" data-wow-delay="0.1s">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal font-main">About Us</h5>
                        <h1 className="mb-4 ">Welcome to <br /><i className="fa fa-utensils text-primary me-2"></i>Burhanpur Jalebi Centre</h1>
                        <p className="mb-4">Indian sweets or Mithais, an integral part of the Indian cuisine, we at The Taste of Burhanpur bring you irreplaceable delicacies filled with love and care. Get the best of famous sweet Jalebi in differents cities with The Taste of Burhanpur.</p>
                        <p className="mb-4">For the last 39 years, we have been winning the hearts of people with the taste of Jalebi, and will continue to win even further. </p>
                        <div className="row g-4 mb-4">

                            <div className="col-sm-7 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                    <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">
                                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                            {
                                                CounterOn && <CountUp start={1900} end={1984} duration={5.75} />
                                            }
                                                
                                        </ScrollTrigger>
                                    </h1>
                                    <div className="ps-3">
                                        <p className="mb-0">Year of</p>
                                        <h6 className="text-uppercase mb-0">Establishment</h6>
                                    </div>
                                </div>
                            </div>


                            <div className="col-sm-5 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                    <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">
                                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                            {
                                                CounterOn && <CountUp start={0} end={39} duration={3.55} />
                                            }
                                        </ScrollTrigger>

                                        {/* <Counter begin={0} end={39} time={2000} easing="outCube" /> */}
                                    </h1>
                                    <div className="ps-3
                            0">
                                        <p className="mb-0">Years of</p>
                                        <h6 className="text-uppercase mb-0">Experience</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            location.pathname === '/' && <Link to='/about' className="btn btn-primary py-3 px-5 mt-2 wow fadeInUp " data-wow-delay="0.2s" >Read More</Link>
                        }

                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default AboutContent