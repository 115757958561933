import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';


const Contact = () => {
    
    return (
        <>
            <div className="container-fluid bg-white p-0 pb-5">
                {/* <!-- Spinner Start --> */}
                {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
                {/* <!-- Spinner End --> */}


                {/* <!-- Navbar & Hero Start --> */}
                <div className="container-fluid position-relative p-0">
                    {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
                <a href="" className="navbar-brand p-0">
                    <h1 className="text-primary m-0"><i className="fa fa-utensils me-3"></i>Restoran</h1>
                     <img src="img/logo.png" alt="Logo"/> 
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 pe-4">
                        <a href="index.html" className="nav-item nav-link">Home</a>
                        <a href="about.html" className="nav-item nav-link">About</a>
                        <a href="service.html" className="nav-item nav-link">Service</a>
                        <a href="menu.html" className="nav-item nav-link">Menu</a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu m-0">
                                <a href="booking.html" className="dropdown-item">Booking</a>
                                <a href="team.html" className="dropdown-item">Our Team</a>
                                <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                            </div>
                        </div>
                        <a href="contact.html" className="nav-item nav-link active">Contact</a>
                    </div>
                    <a href="" className="btn btn-primary py-2 px-4">Book A Table</a>
                </div>
            </nav> */}

                    <div className="container-fluid py-5 bg-dark hero-header mb-5">
                        <div className="container text-center my-5 pt-5 pb-4">
                            <h1 className="display-3 text-white mb-3 animated slideInDown">Contact Us</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center text-uppercase">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Contact</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <!-- Navbar & Hero End --> */}


                {/* <!-- Contact Start --> */}
                <div className="container-fluid py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="section-title ff-secondary text-center text-primary fw-normal">Contact Us</h5>
                            <h1 className="mb-5">Contact For Any Query</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-12 mb-3">
                                <div className="row gy-4">
                                    <div className="col-md-4 text-center">
                                        <h5 className="section-title ff-secondary fw-normal text-start text-primary">Facebook</h5>
                                        <h1><a className="btn btn-outline-dark" href=""><i className="fab fa-facebook-f"></i></a></h1>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h5 className="section-title ff-secondary fw-normal text-start text-primary">Instagram</h5>
                                        <h1><a className="btn btn-outline-dark btn-social" href=""><i className="fab fa-instagram"></i></a></h1>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h5 className="section-title ff-secondary fw-normal text-start text-primary">Whatsapp</h5>
                                        <h1><a className="btn btn-outline-dark btn-social font-weight-bold " href=""><i className="fab fa-whatsapp"></i></a></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                                {/* <iframe className="position-relative rounded w-100 h-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                            frameborder="0" style={{minHeight: 350, border:0}} allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe> */}
                                <iframe
                                    className="position-relative rounded w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14867.973258114327!2d76.2320542!3d21.3112805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe1b4dea42995333f!2sBurhanpur%20Jalebi%20Center!5e0!3m2!1sen!2sin!4v1672773006052!5m2!1sen!2sin"
                                    //  width="auto" height="400"
                                    // style={{ minHeight: 350, border: 0 }}
                                    // allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                    frameBorder="0" style={{ minHeight: 350, border: 0 }} allowFullScreen="" aria-hidden="false"
                                    tabIndex="0"
                                ></iframe>
                            </div>
                            <div className="col-md-6">
                                <div className="wow fadeInUp" data-wow-delay="0.2s">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                    <label htmlFor="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                    <label htmlFor="subject">Subject</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: 150 }}></textarea>
                                                    <label htmlFor="message">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Contact End --> */}


                {/* <!-- Footer Start --> */}

                {/* <!-- Footer End --> */}


                {/* <!-- Back to Top --> */}
                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
            </div>

            {/* <!-- JavaScript Libraries -->
    <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
    <script src="lib/wow/wow.min.js"></script>
    <script src="lib/easing/easing.min.js"></script>
    <script src="lib/waypoints/waypoints.min.js"></script>
    <script src="lib/counterup/counterup.min.js"></script>
    <script src="lib/owlcarousel/owl.carousel.min.js"></script>
    <script src="lib/tempusdominus/js/moment.min.js"></script>
    <script src="lib/tempusdominus/js/moment-timezone.min.js"></script>
    <script src="lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js"></script>

    <!-- Template Javascript -->
    <script src="js/main.js"></script> */}
        </>
    )
}

export default Contact