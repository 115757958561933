import LogoLight from '../Image/LogoLight.png';
import LogoBack from '../Image/LogoBack.png'
import LogoText from '../Image/LogoText.png'
import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import AboutContent from './AboutContent';
import OurFamily from './OurFamily';
import MenuContent from './MenuContent';
import { useLocation } from 'react-router-dom';
import FeedBack from './FeedBack';
import FeedBackContent from './FeedBackContent';

// import AnimationCount from 'react-count-animation';

function Home() {


    return (
        <>
            <div className="container-fuild bg-white p-0">

                <div className="container-fuild position-relative p-0">

                    {/* <Navigation/> */}
                    <div className="container-fuild py-5 bg-dark hero-header mb-5">
                        <div className="container my-5 py-5 responsive-heder">
                            <div className="row align-items-center ">
                                <div className="col-lg-6 text-center text-lg-start">
                                    <h1 className="display-3 text-white animated wow fadeInUp font-main" data-wow-delay="0.1s">Taste Of<br />Burhanpur</h1>
                                    <p className="text-white animated wow fadeInUp mb-4 pb-2" data-wow-delay="0.4s">Get the authentic taste of our Burhanpur.</p>

                                </div>
                                <div className="col-lg-6  ">

                                    <div className="image-container">
                                        <img className="img-fluid-logo back-img" src={LogoBack} width="350" alt="" />
                                        <img className="img-fluid-logo position-absolute mb-5" src={LogoText} width="350" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AboutContent />

                <MenuContent />

                <OurFamily />

                <FeedBackContent />

                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
            </div>


        </>
    );
}

export default Home;
