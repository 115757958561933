import React, { useEffect } from 'react'
import LogoLight from '../Image/LogoLight.png';
import { Outlet, Link, useLocation } from 'react-router-dom'


const Navigation = () => {
    const location = useLocation()
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [location.pathname]);
    return (
        <>
        {
            location.pathname !== '/qr' ? <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-sm-0 py-3 py-lg-0 ">
                <a href="" className="navbar-brand p-0 ">
                    <p className="text-white font-main font-sm m-0"><span><img src={LogoLight} className='mb-1' width={45} /></span> Burhanpur Jalebi Centre<span className="custom-badge mb-5">TM</span></p>
                    
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 pe-4">
                        <Link to='/' className={location.pathname === "/" ? "nav-item nav-link active" : "nav-item nav-link"}>Home</Link>
                        <Link to='/about' className={location.pathname === "/about" ? "nav-item nav-link active" : "nav-item nav-link"}>About</Link>

                        {/* <a href="service.html" className="nav-item nav-link">Service</a> */}
                        <Link to='/menu' className={location.pathname === "/menu" ? "nav-item nav-link active" : "nav-item nav-link"}>Menu</Link>
                        <Link to='/branches' className={location.pathname === "/branches" ? "nav-item nav-link active" : "nav-item nav-link"}>Branches</Link>
                        <Link to='/contact' className={location.pathname === "/contact" ? "nav-item nav-link active" : "nav-item nav-link"}>Contact</Link>
                    </div>
                    <Link to='/feedback' className="btn btn-primary py-2 px-4 ">Feedback</Link>
                </div>
            </nav>
            </> : ""
        }
            
            <section>
                <Outlet />
            </section>
        </>
    )
}

export default Navigation