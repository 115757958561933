import React from 'react'
import BranchesContent from './BranchesContent'
import MenuContent from './MenuContent'

const Branchs = () => {
  return (
    <div>
        <div className="container-fluid py-5 bg-dark hero-header ">
        <div className="container text-center my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Branches</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-uppercase">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item"><a href="#">Menu</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">About</li>
            </ol>
          </nav>
        </div>
      </div>
        <BranchesContent/>
        </div>
  )
}

export default Branchs