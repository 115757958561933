import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import About from './Component/About'
import Branchs from './Component/Branchs'
import Contact from './Component/Contact'
import FeedBack from './Component/FeedBack'
import Footer from './Component/Footer'
import Home from './Component/Home'
import Menu from './Component/Menu'
import Navigation from './Component/Navigation'
import QRCodeContent from './Component/QRCodeContent'
import ScrollToTop from './Component/ScrollToTop'
import SmoothScroll from './Component/SmoothScroll'

const MainRoutes = () => {

  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Navigation />}>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/contact' element={<Contact />} />
              <Route exact path='/branches' element={<Branchs />} />
              <Route exact path='/menu' element={<Menu />} />
              <Route exact path='/feedback' element={<FeedBack />} />
              <Route exact path='/qr' element={<QRCodeContent />} />
            </Route>
          </Routes>
        
        <Footer />
      </BrowserRouter>
      
    </>
  )
}

export default MainRoutes