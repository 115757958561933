import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import rasmalai from '../Image/rasmalai.jpg';
import rasgulla from '../Image/rasgulla.jpg';
import jalebi from '../Image/jalebi.jpg';
// import rasmalai from '../Image/rasmalai.jpg';
import gulabJamun from '../Image/gulabJamun.jpg';

import MenuContent from './MenuContent';

const Menu = () => {
  const location = useLocation()
  return (
    <>
    
    {
      location.pathname !== '/qr' ? 
      <>
      <div className='pb-5'>
      <div className="container-fluid py-5 bg-dark hero-header ">
        <div className="container text-center my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Menu</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-uppercase">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item"><a href="#">Menu</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">About</li>
            </ol>
          </nav>
        </div>
      </div>
      <MenuContent />
      <div className="container-fuild ">
        <div className="container">
          <div className="tab-className text-center wow fadeInUp" data-wow-delay="0.1s">
            <div className="container-fuild py-2">
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item  rounded ">
                      <div className="p-0 team-item overflow-hidden">
                        <img className="card-img-top " src={jalebi} width={150} />
                        <div className='py-1 pb-3 pt-4'>
                          <h5>Spl. Mango Angoori</h5>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
      </> : ""
    }
    </>
    
    
  )
}

export default Menu