import React, { useEffect, useState } from 'react'

import FeedBackContent from './FeedBackContent';

const baseURL = process.env.REACT_APP_BASEURL;

const FeedBack = () => {



  return (
    <>
    <div className='pb-5'>
        <FeedBackContent/>
      </div>
      
    </>
  )
}

export default FeedBack