import React from 'react'
import img6 from '../Image/img-6.jpg';

const BranchesContent = () => {
  return (
    <div>
      <div className="container-fuild py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal">Our Branches</h5>
            <h1 className="pb-5">Most Popular Branches</h1>
          </div>
          <div>
            <div className="row g-1 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-2">
                  <div>
                    <img src={img6} alt='image'/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="section-title ff-secondary text-start text-primary fw-normal">Burhanpur jalebi center</h5>
                <h2 className="mb-4">Mumbai</h2>
                <p className="mb-4">Located amidst the bustling streets of Mumbai, the Mumbai branch of Burhanpur Jalebi Centre stands as a beacon of delectable treats in the city's culinary landscape. Renowned for its exquisite traditional sweets and warm hospitality, this famous sweet shop captivates the hearts and taste buds of locals and visitors alike.</p>

                <p className="mb-4">With its commitment to quality, tradition, and innovation, the Mumbai branch of Burhanpur Jalebi Centre continues to be a symbol of culinary excellence, weaving its way into the hearts of sweet enthusiasts across the city, one delectable bite at a time.</p>
               
              </div>
            </div>
          </div> 
          <div>
            <div className="row g-1 align-items-center pt-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-2">
                  <div>
                    <img src={img6} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="section-title ff-secondary text-start text-primary fw-normal">Burhanpur Jalebi Centre</h5>
                <h2 className="mb-4">Bhusawal</h2>
                <p className="mb-4">In the heart of Bhusawal, the Burhanpur Jalebi Centre extends its renowned legacy, offering a delectable haven for sweet aficionados. This branch, akin to its counterparts, boasts a rich assortment of traditional Indian sweets, each meticulously crafted to perfection. From the aromatic allure of freshly made jalebis to the creamy indulgence of rasgullas, patrons are treated to an exquisite culinary experience that reflects the essence of time-honored recipes.</p>
                
                <p className="mb-4">With its unwavering commitment to quality and tradition, the Bhusawal branch of Burhanpur Jalebi Centre continues to captivate the palates of locals and travelers alike. As a beacon of culinary excellence, it stands testament to the enduring charm and irresistible allure of authentic Indian sweets, inviting patrons to embark on a delightful journey of flavors and memories.</p>

              </div>
            </div>
          </div>
          <div>
            <div className="row g-1 align-items-center pt-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-2">
                  <div>
                    <img src={img6} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="section-title ff-secondary text-start text-primary fw-normal">Burhanpur Jalebi Centre</h5>
                <h2 className="mb-4">Burhanpur</h2>
                <p className="mb-4">At the heart of Burhanpur, the main branch of Burhanpur Jalebi Centre stands as a timeless symbol of culinary excellence and cultural heritage. Here, amidst the bustling streets and vibrant atmosphere, patrons embark on a journey of gastronomic delight, indulging in the irresistible flavors of traditional Indian sweets meticulously prepared with passion and expertise.</p>
                <p className="mb-4">The main branch of Burhanpur Jalebi Centre is more than just a sweet shop; it is a beloved institution deeply ingrained in the fabric of the local community. Generations have gathered under its roof to celebrate milestones, share stories, and savor the simple pleasures of life encapsulated in every bite of its delectable offerings.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BranchesContent