import React, {  useState } from 'react'
import MainRoutes from './MainRoutes';
// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';
import "./Component/styles.css"

function App() {

    return (
        <>
            <MainRoutes/>
        </>
    );
}

export default App;
