import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating';
import img6 from '../Image/img-6.jpg';
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2';
import Slider from 'react-slick';

const baseURL = process.env.REACT_APP_BASEURL;

const feedback = {
    userId: Math.random().toString(36).slice(2),
    name: "",
    mobileNo: "",
    date: new Date(),
    message: "",
    rating: 0

}

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
          
        }
      }
    ]
  };

const FeedBackContent = () => {
    const location = useLocation()
    const [feedbackData, setFeedbackData] = useState([]);
    // const [feedbacks, setFeedbacks] = useState([]);
    const [newRecord, setNewRecord] = useState(feedback)
    // // Get data
    const FetchData = async () => {
        const res = await fetch(baseURL)
        const resJson = await res.json();
        setFeedbackData(resJson)

    }
    useEffect(() => {
        FetchData();
    }, [])

    // // insert data

    const addPosts = async (body) => {
        let response = await fetch(baseURL, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });   
        let data = await response.json();
        // setFeedbacks((feedbacks) => [data, ...feedbacks]);
        setFeedbackData([data,...feedbackData])
        setNewRecord(feedback)
    };

    const onSendFeedbackClick = (e) => {
        e.preventDefault();
        if(newRecord.name !== "" && newRecord.message !== ""){
            addPosts(newRecord);
            // alert("feedback send")
            Swal.fire({
                icon: 'success',
                title: 'Thanks for your feedback.',
                showConfirmButton: false,
                timer: 2000
              })
        }else if(newRecord.name === "" && newRecord.message !== ""){
            Swal.fire({
                icon: 'warning',
                text: 'Please enter your name.',
                confirmButtonColor:"#250B0B",
                // allowOutsideClick:true
              })
            // alert("Please enter your name.")
        }else if(newRecord.name !== "" && newRecord.message === ""){
            Swal.fire({
                icon: 'warning',
                text: 'Please enter message.',
                confirmButtonColor:"#250B0B",
                // allowOutsideClick:true
              })
            // alert("Please enter message.")
        }else{
            Swal.fire({
                icon: 'warning',
                text: 'Please enter your Name & Message.',
                confirmButtonColor:"#250B0B",
                // allowOutsideClick:true
              })
            // alert("Please enter your name & message.")
        }
        
    }

    return (
        <>

            <div className={`container-fuild pb-5 px-0 wow fadeInUp ${location.pathname === "/feedback" ? 'pt-0' : 'pt-5'}`} data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="video">
                        </div>
                    </div>
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className={`p-5 wow fadeInUp ${location.pathname === "/feedback" && 'mt-5'}`} data-wow-delay="0.2s">
                            <h5 className={`section-title ff-secondary text-start text-primary fw-normal font-main `}>Feedback</h5>
                            <h3 className="text-white mb-4">Drop Your Feedback Here!</h3>
                            <form>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Your Name"
                                                value={newRecord.name}
                                                onChange={(e) =>
                                                    setNewRecord({ ...newRecord, name: e.target.value })
                                                }
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                id="number"
                                                placeholder="Mobile no"
                                                value={newRecord.mobileNo}
                                                onChange={(e) =>
                                                    setNewRecord({ ...newRecord, mobileNo: e.target.value })
                                                }
                                            />
                                            <label htmlFor="number">Mobile No.</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating date" id="date3" data-target-input="nearest">
                                            <input
                                                type="date"
                                                className="form-control datetimepicker-input"
                                                id="datetime"
                                                placeholder="Date & Time"
                                                // data-target="#date3"
                                                data-toggle="datetimepicker"
                                                value={newRecord.date}
                                                onChange={(e) => {
                                                    setNewRecord({ ...newRecord, date: e.target.value })
                                                    console.log(e)
                                                }}

                                            />
                                            <label htmlFor="datetime">Date</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Special Request"
                                                id="message"
                                                style={{ height: 100 }}
                                                value={newRecord.message}
                                                onChange={(e) =>
                                                    setNewRecord({ ...newRecord, message: e.target.value })
                                                }
                                            ></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="row pt-3 text-center text-white " >
                                        <p>Rate Us</p>
                                    </div>
                                    <div className="row text-center" >
                                        <Rating
                                            fillColor={'#fdde6c'}
                                            emptyColor={'#ffffff'}
                                            initialValue={newRecord.rating}
                                            onClick={(e) =>
                                                setNewRecord({ ...newRecord, rating: e })
                                            }
                                        // onPointerEnter={onPointerEnter}
                                        // onPointerLeave={onPointerLeave}
                                        // onPointerMove={onPointerMove}
                                        /* Available Props */
                                        />
                                    </div>

                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3 " onClick={onSendFeedbackClick} type="submit">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div><img src={img6} /></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Testimonal /> */}
            <div className='container-fluid pb-5 pt-4'>
                <div className="text-center pt-4">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal font-main">Testimonial</h5>
                    <h1 className="">Our Customers Say!!!</h1>
                </div>

                {/* <Slider {...settings}>
          <div>
            <h3>1</h3>
          </div>
        </Slider> */}
        <div className='mt-5 container-xxl container-xl container-lg pb-5 mb-5'>
        <Slider {...settings} >
                    {
                        feedbackData.map((val, i) =>
                            <>
                                    <div style={{height:"300px"}} className="bg-transparent mx-2 
                                     hover-bg    rounded  testimonal-height shadow-sm border    my-2 p-4" key={i}>
                                        <i className="fa fa-quote-left fa-2x   mb-3"></i>
                                        <div className='h-auto'>
                                        <p>{val.message}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="ps-1">
                                                <h5 className="mb-1">{val.name}</h5>
                                                <p className='font-size-10 mb-2'>{new Date (val.date).toLocaleDateString()}</p>
                                                <Rating
                                                    initialValue={val.rating}
                                                    readonly={true}
                                                    size={20}
                                                    fillColor={'#fdde6c'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            </>
                        )
                    }
                </Slider>
                </div>
                
            </div>
        </>
    )
}

export default FeedBackContent