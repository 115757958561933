import React, { useEffect } from 'react'
import AboutContent from './AboutContent';
import OurFamily from './OurFamily';

const About = () => {

  return (
    <div>
      <div className="container-fluid bg-white p-0 pb-5">
       
        <div className="container-fluid position-relative p-0">

          <div className="container-fluid py-5 bg-dark hero-header mb-5">
            <div className="container text-center my-5 pt-5 pb-4">
              <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center text-uppercase">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Menu</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* <!-- Navbar & Hero End -->


        <!-- About Start --> */}
        <AboutContent />
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    {/* <i className="fa fa-3x fa-user-tie text-primary mb-4"></i> */}
                    <i className="fa fa-3x fa-heart text-primary mb-4"></i>
                    <h5>Health</h5>
                    <p>Your health is wealth. We strive to serve you with the best and healthy snacks that will add years to your life. <br/> <br/> <br/> <br/></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-utensils text-primary mb-4"></i>
                    <h5>Fine Ingredients</h5>
                    <p>Fine Ingredients bring you the traditional flavors of the Indian cuisine. Our products highlight rich flavor combinations made with the best quality ingredients entirely from the menu.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-utensils text-primary mb-4"></i>
                    <h5>Pure & Natural </h5>
                    <p>Quality over quantity. We assure the 100% purity of our sweets. Fresh, pure and the best taste is what describes us. <br/><br/><br/></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-utensils text-primary mb-4"></i>
                    <h5>Consistancy</h5>
                    <p>From last 38 years we are continuously making good product. <br/><br/><br/><br/><br/></p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-user-tie text-primary mb-4"></i>
                    <h5>Master Chefs</h5>
                    <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-utensils text-primary mb-4"></i>
                    <h5>Quality Food</h5>
                    <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-cart-plus text-primary mb-4"></i>
                    <h5>Online Order</h5>
                    <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <i className="fa fa-3x fa-headset text-primary mb-4"></i>
                    <h5>24/7 Service</h5>
                    <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- About End -->


        <!-- Team Start --> */}
        <OurFamily />
        {/* <!-- Team End -->
        



        <!-- Back to Top -->  */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
      </div>
    </div>

  )
}

export default About    