import React from 'react'

const QRCodeContent = () => {
  return (
    <div className='text-center mt-5 pt-5'>
        <div className='mt-2'>
            <a href='https://www.burhanpurjalebicentre.com/' className="btn btn-primary py-3 px-5 mt-2 wow fadeInUp rounded-pill " data-wow-delay="0.2s" >Website</a>
        </div>
        <div className='mt-2'>
            <a href='https://www.facebook.com/people/Burhanpur-Jalebi-Centre/100070403883793/' className="btn btn-primary py-3 px-5 mt-2 wow fadeInUp rounded-pill " data-wow-delay="0.2s" >facebook</a>
        </div>
        <div className='mt-2'>
            <a href='https://www.instagram.com/burhanpurjalebicentre/' className="btn btn-primary py-3 px-5 mt-2 wow fadeInUp rounded-pill " data-wow-delay="0.2s" >Instagram</a>
        </div>
        
         </div>
  )
}

export default QRCodeContent