import React, { useEffect, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import profile from '../Image/profile.jpg';

const OurFamily = () => {
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])
    let screenResolution = screenSize.dynamicWidth

    const options = {
        type: 'slide',
        gap: '0.5rem',
        autoplay: true,
        // autoScroll: {
        //     speed: 1,
        // },
        pagination: false,
        arrows: false,
        pauseOnHover: false,
        resetProgress: false,
        height: 'auto',
        perPage: 4,
        perMove: 1,
        // focus: 'center',
        arrowPath: 'm15.5 0.932-4.3 4.38...',
        breakpoints: {
            640: {
                perPage: 2,
                gap: '.7rem',
                // height : '6rem',
            },
            480: {
                perPage: 1,
                gap: '.7rem',
                // height : '6rem',
            },
        },
    };

    return (

        <div>
            {
                screenResolution <= 700 ?
                    <>
                        <div className="container-fuild pt-5 pb-3">
                            <div className="container">
                                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <h5 className="section-title ff-secondary text-center text-primary fw-normal font-main">Team Members</h5>
                                    <h1 className="mb-5">Our Family</h1>
                                </div>
                                <div className="row g-4">
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Abdul Karim</h5>

                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mushtaque Ahmad</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohammed Ashfaque</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohmmad Abrar</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohmmad Irfan</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='container-fluid pb-5  pt-5 mt-4'>
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="section-title ff-secondary text-center text-primary fw-normal font-main">Team Members</h5>
                                <h1 className="mb-5">Our Family</h1>
                            </div>
                            <div className='px-xl-5 mx-3'>
                                <Splide options={options} aria-label="My Favorite Images">
                                    <SplideSlide className='w-25'>
                                        <div className="team-item  text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Abdul Karim</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide className='w-25'>
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mushtaque Ahmad</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide className='w-25'>
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohmmad Ashfaque</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide className='w-25'>
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohmmad Abrar</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide className='w-25'>
                                        <div className="team-item text-center rounded overflow-hidden">
                                            <div className="rounded-circle overflow-hidden m-4">
                                                <img className="img-fluid" src={profile} alt="" width={230} height={230} />
                                            </div>
                                            <h5 className="mb-0">Mohmmad Irfan</h5>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                                <a className="btn btn-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    

                                </Splide>
                            </div>
                        </div>
                    </>
            }</div>
    )
}

export default OurFamily