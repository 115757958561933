import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LogoLight from '../Image/LogoLight.png';

const Footer = () => {
const Navigate = useNavigate();

const location = useLocation()
const NavigateToAbout = () =>{
    Navigate('/about')
}

const NavigateToContact = () =>{
    Navigate('/contact')
}

const NavigateToMenu = () =>{
    Navigate('/menu')
}

const NavigateToFeedback = () =>{
    Navigate('/feedback')
}

const NavigateToBranches = () =>{
    Navigate('/branches')
}
    return (
        <>
        {
            location.pathname !== "/qr" ? 
            <>
             <div className="container-fluid bg-dark text-light footer pt-5  wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 font-main">Visit</h4>
                            <a className="btn btn-link"  onClick={NavigateToAbout}>About Us</a>
                            <a className="btn btn-link" onClick={NavigateToMenu}>Menu</a>
                            <a className="btn btn-link" onClick={NavigateToBranches}>Branches</a>
                            <a className="btn btn-link" onClick={NavigateToContact}>Contact Us</a>
                            <a className="btn btn-link" onClick={NavigateToFeedback}>Feedback</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 font-main">Contact</h4>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Subhash chowk Burhanpur, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(M.P.) 450331</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>9826272490 / 9340161643</p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>burhanpurjalebicentre@gmail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.com</p>
                            <div className="d-flex pt-2">
                                {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a> */}
                                <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/people/Burhanpur-Jalebi-Centre/100070403883793/"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-social" href=" https://wa.me/[9691890908]"><i className="fab fa-whatsapp"></i></a>
                                <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 font-main">Open</h4>
                            <h5 className="text-light fw-normal">Monday To Sunday</h5>
                            <p>09:00 AM - 11:00 PM</p>
                            {/* <h5 className="text-light fw-normal">Sunday</h5>
                        <p>10AM - 08PM</p> */}
                        </div>
                        <div className="col-lg-3 col-md-6">
                          
                            <img src={LogoLight} width={180} />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">Burhanpur Jalebi Centre</a>, All Right Reserved.

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </>
            :""
        }
           
        
        </>
    )
}

export default Footer